import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useSelector } from "react-redux";

export const AppViews = () => {
  let history = useHistory();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token) {
      history.push("/auth/login");
    }
  });

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />

        {/* hotels */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotels`}
          component={lazy(() => import(`./hotels`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotels/create`}
          component={lazy(() => import(`./hotels/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotel/:id`}
          component={lazy(() => import(`./hotels/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotels/:id/room`}
          component={lazy(() => import(`./hotels/room`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotels/:id/room/create`}
          component={lazy(() => import(`./hotels/room/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/hotels/:id/room/edit/:roomId`}
          component={lazy(() => import(`./hotels/room/create`))}
        />

        {/* summer house */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dacha`}
          component={lazy(() => import(`./dacha`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dacha/create`}
          component={lazy(() => import(`./dacha/create`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dacha/:id`}
          component={lazy(() => import(`./dacha/edit`))}
        />

        {/* Recreation */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation`}
          component={lazy(() => import(`./recreation`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation/create`}
          component={lazy(() => import(`./recreation/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation/:id`}
          component={lazy(() => import(`./recreation/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation/:id/room`}
          component={lazy(() => import(`./recreation/room`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation/:id/room/create`}
          component={lazy(() => import(`./recreation/room/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/recreation/:id/room/edit/:roomId`}
          component={lazy(() => import(`./recreation/room/create`))}
        />

        {/* Sanatorium */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium`}
          component={lazy(() => import(`./sanatorium`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium/create`}
          component={lazy(() => import(`./sanatorium/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium/:id`}
          component={lazy(() => import(`./sanatorium/edit`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium/:id/room`}
          component={lazy(() => import(`./sanatorium/room`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium/:id/room/create`}
          component={lazy(() => import(`./sanatorium/room/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sanatorium/:id/room/edit/:roomId`}
          component={lazy(() => import(`./sanatorium/room/create`))}
        />

        {/* Guide */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/guides`}
          component={lazy(() => import(`./guide`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/guides/create`}
          component={lazy(() => import(`./guide/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/guides/:id`}
          component={lazy(() => import(`./guide/edit`))}
        />

        {/* Subscription */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/subscriptions`}
          component={lazy(() => import(`./subscription`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/subscriptions/create`}
          component={lazy(() => import(`./subscription/create`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/subscriptions/:id`}
          component={lazy(() => import(`./subscription/edit`))}
        />

        {/* Features */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/features`}
          component={lazy(() => import(`./feature`))}
        />

        {/* Attributes */}

        <Route
          exact
          path={`${APP_PREFIX_PATH}/attributes`}
          component={lazy(() => import("./attribute"))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/attributes/create`}
          component={lazy(() => import("./attribute/add"))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/attributes/:id`}
          component={lazy(() => import("./attribute/edit"))}
        />
        
        {/* Attribute groups */}

        <Route
          exact
          path={`${APP_PREFIX_PATH}/attribute-groups`}
          component={lazy(() => import("./attributeGroup"))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/attribute-groups/create`}
          component={lazy(() => import("./attributeGroup/add"))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/attribute-groups/:id`}
          component={lazy(() => import("./attributeGroup/edit"))}
        />

        {/* blog */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog`}
          component={lazy(() => import(`./blog`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog/create`}
          component={lazy(() => import(`./blog/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog/:id`}
          component={lazy(() => import(`./blog/edit`))}
        />

        {/* category */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog-category`}
          component={lazy(() => import(`./blogCategory`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog-category/create`}
          component={lazy(() => import(`./blogCategory/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/blog-category/:id`}
          component={lazy(() => import(`./blogCategory/edit`))}
        />

        {/*residences-area-type*/}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-type`}
          component={lazy(() => import(`./residenceAreaType`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-type/create`}
          component={lazy(() => import(`./residenceAreaType/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-type/:id`}
          component={lazy(() => import(`./residenceAreaType/edit`))}
        />

        {/*residences-area-title*/}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-title`}
          component={lazy(() => import(`./residenceAreaTitle`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-title/create`}
          component={lazy(() => import(`./residenceAreaTitle/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/residences-area-title/:id`}
          component={lazy(() => import(`./residenceAreaTitle/edit`))}
        />

        {/* footer */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/footer`}
          component={lazy(() => import(`./footer`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/footer/create`}
          component={lazy(() => import(`./footer/create`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/footer/:id`}
          component={lazy(() => import(`./footer/edit`))}
        />

        {/* users */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/users/create`}
          component={lazy(() => import(`./users/create`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users/:id`}
          component={lazy(() => import(`./users/edit`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cabinet`}
          component={lazy(() => import(`./cabinet`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/approved-residences`}
          component={lazy(() => import(`./approvedResidences`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
