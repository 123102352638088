import React from 'react'
import { Dropdown, Menu, Space } from 'antd'
import { Link } from 'react-router-dom'
import { UserOutlined, TranslationOutlined } from '@ant-design/icons'
import { onLocaleChange, signOutSuccess } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

export default function UserDropdown() {
  const dispatch = useDispatch()

  const localeChange = (event, lang) => {
    event.preventDefault()
    dispatch(onLocaleChange(lang))
  }

  const logout = (event) => {
    event.preventDefault()
    dispatch(signOutSuccess())
  }

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <Link to='/app/cabinet'>
          <FormattedMessage id='Cabinet' />
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link to='/cabinet' onClick={logout}>
          <FormattedMessage id='SignOut' />
        </Link>
      </Menu.Item>
    </Menu>
  )

  const langMenu = (
    <Menu>
      <Menu.Item key={1}>
        <a href='/' onClick={(event) => localeChange(event, 'en')}>
          English
        </a>
      </Menu.Item>
      <Menu.Item key={2}>
        <a href='/' onClick={(event) => localeChange(event, 'ru')}>
          Русский
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Space size='large'>
      <Dropdown overlay={menu}>
        <div className='d-flex align-items-center cursor-pointer'>
          <UserOutlined style={{ fontSize: '18px' }} />
          <span className='ml-2'>Profile</span>
        </div>
      </Dropdown>

      <Dropdown overlay={langMenu}>
        <div className='h-100 cursor-pointer pr-4'>
          <TranslationOutlined
            style={{ fontSize: '18px', verticalAlign: 'middle' }}
          />
        </div>
      </Dropdown>
    </Space>
  )
}
