import {
    AUTH_TOKEN,
    AUTHENTICATED,
    SHOW_AUTH_MESSAGE,
    HIDE_AUTH_MESSAGE,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    SHOW_LOADING,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    UserData
} from '../constants/Auth'

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN),
    user: null,
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/',
                token: authenticateUser(action),
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false,
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS: {
            localStorage.removeItem("auth_token")
            localStorage.removeItem("token")
            localStorage.removeItem("userData")
            return {
                ...state,
                token: null,
                redirect: '/',
                loading: false,
            }

        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token,
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }
        case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token,
            }
        }
        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token,
            }
        }
        case UserData: {
            return {
                ...state,
                user: action.payload.user,
            }
        }
        default:
            return state
    }
}

export default auth

function authenticateUser(action) {
    const access_token = action.token.token_type + ' ' + action.token.access_token
    localStorage.setItem(AUTH_TOKEN, access_token)
    return access_token
}
