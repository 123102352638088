import { ADD_RESIDENCE_ID , ADD_CITY_ID} from '../constants/Residence'

const initialState = {
  id: null,
  city_id: null
}

const residence = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESIDENCE_ID:
      return {
        ...state,
        id: action.payload,
      }
    case ADD_CITY_ID:
      return {
        ...state,
        city_id: action.payload,
      }
    default:
      return state
  }
}

export default residence
