import antdJaJP from 'antd/es/locale/ja_JP';
import uzMsg from "../locales/uz_UZ.json";

const UzLang = {
  antd: antdJaJP,
  locale: 'uz-UZ',
  messages: {
    ...uzMsg
  },
};
export default UzLang;
