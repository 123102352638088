import {
  DashboardOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
  NodeIndexOutlined,
  EnvironmentOutlined,
  CompassOutlined,
  BankOutlined,
  PieChartOutlined,
  ReadOutlined,
  StrikethroughOutlined,
  BoldOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ProjectOutlined,
  SelectOutlined,
  TableOutlined,
  GroupOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { BiCategoryAlt } from 'react-icons/bi'

const dashBoardNavTree = [
  {
    key: 'damda',
    path: `${APP_PREFIX_PATH}/`,
    title: 'damda',
    breadcrumb: false,
    submenu: [
      {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'home',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'residences',
        title: 'residences',
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'hotels',
            path: `${APP_PREFIX_PATH}/hotels`,
            title: 'hotels',
            icon: HomeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'dacha',
            path: `${APP_PREFIX_PATH}/dacha`,
            title: 'dacha',
            icon: EnvironmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'sanatorium',
            path: `${APP_PREFIX_PATH}/sanatorium`,
            title: 'sanatorium',
            icon: StrikethroughOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'recreation',
            path: `${APP_PREFIX_PATH}/recreation`,
            title: 'recreation',
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },
          // {
          //   key: 'guides',
          //   path: `${APP_PREFIX_PATH}/guides`,
          //   title: 'guides',
          //   icon: CompassOutlined,
          //   breadcrumb: true,
          //   submenu: [],
          // },
          // {
          //   key: 'tours',
          //   path: `${APP_PREFIX_PATH}/tours`,
          //   title: 'tours',
          //   icon: NodeIndexOutlined,
          //   breadcrumb: true,
          //   submenu: [],
          // },
        ],
      },
      {
        key: 'approved-residences',
        path: `${APP_PREFIX_PATH}/approved-residences`,
        title: 'pending_residences',
        icon: ProjectOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'orders',
        path: `${APP_PREFIX_PATH}/orders`,
        title: 'orders',
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'subscriptions',
        path: `${APP_PREFIX_PATH}/subscriptions`,
        title: 'subscriptions',
        icon: DollarCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'features',
        path: `${APP_PREFIX_PATH}/features`,
        title: 'features',
        icon: SelectOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'attributes',
        path: `${APP_PREFIX_PATH}/attributes`,
        title: 'attributes',
        icon: TableOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'attribute_groups',
        path: `${APP_PREFIX_PATH}/attribute-groups`,
        title: 'attribute_groups',
        icon: GroupOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: 'cities',
      //   path: `${APP_PREFIX_PATH}/cities`,
      //   title: 'cities',
      //   icon: BankOutlined,
      //   breadcrumb: true,
      //   submenu: [],
      // },
      {
        key: 'blogs',
        title: 'blog',
        icon: BiCategoryAlt,
        breadcrumb: true,
        submenu: [
          {
            key: 'blog',
            path: `${APP_PREFIX_PATH}/blog`,
            title: 'blog',
            icon: BoldOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'blog-category',
            path: `${APP_PREFIX_PATH}/blog-category`,
            title: 'Blog_category',
            icon: ReadOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'footer',
        path: `${APP_PREFIX_PATH}/footer`,
        title: 'footer',
        icon: ReadOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'residencesArea',
        title: 'residences_area',
        icon: PieChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'residencesAreaType',
            path: `${APP_PREFIX_PATH}/residences-area-type`,
            title: 'residences_area_type',
            icon: PieChartOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'residencesAreaTitle',
            path: `${APP_PREFIX_PATH}/residences-area-title`,
            title: 'residences_area_title',
            icon: PieChartOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'users',
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'settings',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
