import { ADD_RESIDENCE_ID ,ADD_CITY_ID } from '../constants/Residence'

export function addResidenceId(payload) {
  return {
    type: ADD_RESIDENCE_ID,
    payload,
  }
}

export function addCityId(payload) {
  return {
    type: ADD_CITY_ID,
    payload,
  }
}