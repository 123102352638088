import { CHANGE_FORM_LANG } from '../constants/FormLanguage'

const initialState = {
  lang: 'en',
  languages: [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'ru',
      label: 'Русский',
    },
    {
      value: 'uz',
      label: "O'zbek",
    },
  ],
}

const formLanguage = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FORM_LANG:
      return {
        ...state,
        lang: action.payload,
      }
    default:
      return state
  }
}

export default formLanguage
