import antdRuUS from 'antd/es/locale/ru_RU'
import ruMsg from '../locales/ru_RU.json'

const RuLang = {
  antd: antdRuUS,
  locale: 'ru-RU',
  messages: {
    ...ruMsg,
  },
}
export default RuLang
