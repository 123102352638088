import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import FormLanguage from './FormLanguage'
import Residence from './Residence'

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  formLang: FormLanguage,
  residence: Residence,
})

export default reducers
