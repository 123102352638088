import enLang from './entries/en_US'
import zhLang from './entries/zh_CN'
import frLang from './entries/fr_FR'
import uzLang from './entries/uz_UZ'
import ruLang from './entries/ru_RU'

const AppLocale = {
  en: enLang,
  zh: zhLang,
  fr: frLang,
  uz: uzLang,
  ru: ruLang,
}

export default AppLocale
